:root {
    --primary-error-background: #FAE1E2;
    --primary-error-color: #FF3A44;
    --primary-success-background: #dce3f1;
    --primary-success-color:  #0D4BA0;
}

.Toastify__toast-container {
    width: auto;
}

.Toastify__toast {
    min-height: auto;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    border-radius: 4px;
    text-align: right;
    font-size: 18px;
    font-family: Robot, sans-serif;
}

.Toastify__toast--error {
    background: var(--primary-error-background);
    color: var(--primary-error-color);;
}

.Toastify__toast--success {
    background-color:  var(--primary-success-background);;
    color: var(--primary-success-color);
}


.Toastify__toast-icon {
    display: none;
}

.Toastify__toast-body > div:last-child {
    word-break:  break-word;
    flex: 1 1;
    text-align: center;
}

@media (max-width: 700px) {
    .Toastify__toast-container{
        width: 100%;
        right: 0;
    }
    .Toastify__toast--error {
        font-size: 16px;
    }
}